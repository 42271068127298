import { StyledPath, StyledSvg } from "./loading-icon.styles";

type Props = {
  size?: number;
  color?: string;
  type?: 'default' | 'empty' | 'error';
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
};

export const LoadingIcon = ({
  size = 200,
  color = 'white',
  type = 'default',
  onClick,
}: Props) => (
    <StyledSvg
      x="0"
      y="0"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      size={size}
      onClick={onClick}
    >
    {type === 'default' && (
      <>
        <StyledPath
          d="M491.729,112.971L259.261,0.745c-2.061-0.994-4.461-0.994-6.521,0L20.271,112.971c-2.592,1.251-4.239,3.876-4.239,6.754    v272.549c0,2.878,1.647,5.503,4.239,6.754l232.468,112.226c1.03,0.497,2.146,0.746,3.261,0.746s2.23-0.249,3.261-0.746    l232.468-112.226c2.592-1.251,4.239-3.876,4.239-6.754V119.726C495.968,116.846,494.32,114.223,491.729,112.971z M256,15.828    l215.217,103.897l-62.387,30.118c-0.395-0.301-0.812-0.579-1.27-0.8L193.805,45.853L256,15.828z M176.867,54.333l214.904,103.746    l-44.015,21.249L132.941,75.624L176.867,54.333z M396.799,172.307v78.546l-41.113,19.848v-78.546L396.799,172.307z     M480.968,387.568L263.5,492.55V236.658l51.873-25.042c3.73-1.801,5.294-6.284,3.493-10.015    c-1.801-3.729-6.284-5.295-10.015-3.493L256,223.623l-20.796-10.04c-3.731-1.803-8.214-0.237-10.015,3.493    c-1.801,3.73-0.237,8.214,3.493,10.015l19.818,9.567V492.55L31.032,387.566V131.674l165.6,79.945    c1.051,0.508,2.162,0.748,3.255,0.748c2.788,0,5.466-1.562,6.759-4.241c1.801-3.73,0.237-8.214-3.493-10.015l-162.37-78.386    l74.505-35.968L340.582,192.52c0.033,0.046,0.07,0.087,0.104,0.132v89.999c0,2.581,1.327,4.98,3.513,6.353    c1.214,0.762,2.599,1.147,3.988,1.147c1.112,0,2.227-0.247,3.26-0.746l56.113-27.089c2.592-1.251,4.239-3.875,4.239-6.754v-90.495    l69.169-33.392V387.568z"
          color={color}
          {...{ 'data-testid': 'loading-icon-default' }}
        />

        <StyledPath
          d="M92.926,358.479L58.811,342.01c-3.732-1.803-8.214-0.237-10.015,3.493c-1.801,3.73-0.237,8.214,3.493,10.015    l34.115,16.469c1.051,0.508,2.162,0.748,3.255,0.748c2.788,0,5.466-1.562,6.759-4.241    C98.22,364.763,96.656,360.281,92.926,358.479z"
          color={color}
        />

        <StyledPath
          d="M124.323,338.042l-65.465-31.604c-3.731-1.801-8.214-0.237-10.015,3.494c-1.8,3.73-0.236,8.214,3.494,10.015    l65.465,31.604c1.051,0.507,2.162,0.748,3.255,0.748c2.788,0,5.466-1.562,6.759-4.241    C129.617,344.326,128.053,339.842,124.323,338.042z"
          color={color}
        />
      </>
    )}

    {type === 'empty' && (
      <StyledPath
        d="M510.978,236.187l-56.113-96.193c-0.778-1.334-1.949-2.395-3.354-3.039L259.125,48.778c-1.984-0.909-4.266-0.909-6.25,0    L60.488,136.955c-1.404,0.644-2.575,1.705-3.354,3.039L1.021,236.187c-1.066,1.829-1.311,4.023-0.672,6.042    c0.639,2.019,2.101,3.673,4.025,4.555l192.387,88.177c3.508,1.608,7.66,0.292,9.604-3.039l42.135-72.231v185.022L71.113,363.411    v-62.822c0-4.142-3.357-7.5-7.5-7.5c-4.143,0-7.5,3.358-7.5,7.5v67.636c0,2.933,1.709,5.596,4.375,6.818l192.387,88.178    c0.992,0.455,2.059,0.682,3.125,0.682s2.133-0.228,3.125-0.682l192.387-88.178c2.666-1.222,4.375-3.885,4.375-6.818v-67.634    c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v62.821L263.5,444.714V259.692l42.135,72.231c1.384,2.373,3.889,3.722,6.482,3.722    c1.049,0,2.111-0.22,3.121-0.683l192.387-88.177c1.925-0.882,3.387-2.537,4.025-4.555    C512.289,240.211,512.045,238.016,510.978,236.187z M196.833,318.494L18.148,236.597l48.518-83.174l178.685,81.897    L196.833,318.494z M256,223.7L81.613,143.773L256,63.846l174.386,79.927L256,223.7z M315.167,318.494l-48.518-83.174    l178.684-81.897l48.518,83.174L315.167,318.494z"
        color={color}
        {...{ 'data-testid': 'loading-icon-empty' }}
      />
    )}

    {type === 'error' && (
      <>
        <StyledPath
          d="M446.849,186.152l-48.873-83.781c-0.778-1.334-1.949-2.396-3.354-3.039L227.06,22.533c-1.984-0.909-4.266-0.909-6.25,0    L53.247,99.332c-1.404,0.644-2.575,1.705-3.354,3.039L1.021,186.153c-1.066,1.829-1.311,4.023-0.672,6.042    c0.639,2.019,2.101,3.673,4.025,4.555l167.563,76.8c1.01,0.463,2.072,0.683,3.121,0.683c2.594,0,5.099-1.35,6.482-3.722    l34.895-59.819v156.061L63.872,296.827v-49.15c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v53.963    c0,2.933,1.709,5.596,4.375,6.818l167.563,76.8c0.992,0.455,2.059,0.682,3.125,0.682s2.133-0.228,3.125-0.682l24.052-11.024    c3.766-1.726,5.419-6.177,3.693-9.943c-1.727-3.765-6.174-5.419-9.943-3.693l-13.427,6.154v-156.06l34.894,59.818    c1.944,3.333,6.098,4.647,9.604,3.039l167.563-76.8c1.925-0.882,3.387-2.537,4.025-4.555    C448.159,190.176,447.916,187.981,446.849,186.152z M172.009,257.081l-153.86-70.52L59.425,115.8l153.862,70.52L172.009,257.081z     M223.935,174.7L74.372,106.15L223.935,37.6l149.562,68.549L223.935,174.7z M275.863,257.081l-41.278-70.762l153.861-70.52    l41.278,70.762L275.863,257.081z"
          color={color}
          {...{ 'data-testid': 'loading-icon-error' }}
        />

        <StyledPath
          d="M392.274,250.698c-66.017,0-119.726,53.709-119.726,119.726S326.257,490.15,392.274,490.15S512,436.441,512,370.424    S458.291,250.698,392.274,250.698z M392.274,475.149c-57.746,0-104.726-46.979-104.726-104.726s46.979-104.726,104.726-104.726    S497,312.678,497,370.424C497,428.17,450.02,475.149,392.274,475.149z"
          color={color}
        />

        <StyledPath
          d="M442.714,341.553c-4.065-19.513-19.908-35.356-39.422-39.423c-15.557-3.242-31.429,0.546-43.544,10.396    c-12.114,9.85-19.063,24.446-19.063,40.045c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-11.063,4.93-21.417,13.526-28.406    c8.595-6.989,19.9-9.667,31.02-7.35c13.761,2.868,24.932,14.04,27.8,27.799c4.005,19.218-6.998,37.755-25.595,43.118    c-10.564,3.047-17.662,12.198-17.662,22.772c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-3.824,2.74-7.184,6.818-8.36    C432.809,394.583,448.338,368.534,442.714,341.553z"
          color={color}
        />

        <StyledPath
          d="M392.354,431.745h-0.08c-4.143,0-7.46,3.358-7.46,7.5c0,4.142,3.397,7.5,7.54,7.5s7.5-3.358,7.5-7.5    C399.855,435.103,396.497,431.745,392.354,431.745z"
          color={color}
        />
      </>
    )}
    </StyledSvg>
);