import { StyledSvg } from "./text-with-brackets.styles";

type Props = {
  height: number;
  side?: 'left' | 'right';
}

export const Bracket = ({ height, side }: Props) => (
  <StyledSvg 
    height={height}
    side={side}
    viewBox="0 0 1.5345832 5.2916675" 
    role="img"
    aria-labelledby="title"
    xmlns="http://www.w3.org/2000/svg"
    >
    <title>bracket</title>   
    <path
      d="m 0.03294725,2.6317573 q 0,-0.5442276 0.13980157,-1.0560012 Q 0.31255039,1.061486 0.58216768,0.63708841 0.85178496,0.21019431 1.1613456,0.02545659 l 0.072397,0.20221295 Q 1.0689767,0.34500302 0.90670706,0.56968405 0.74443736,0.7943652 0.61711805,1.1039259 0.48979882,1.4134865 0.40991221,1.8004372 0.33252203,2.1848915 0.33252203,2.674197 q 0,0.5217594 0.11733348,1.0035756 0.11982992,0.4818161 0.32703575,0.8487952 0.20720592,0.3694754 0.45685154,0.5542135 l -0.072397,0.192227 Q 0.84429562,5.0832776 0.57467834,4.6588797 0.30506102,4.2369788 0.16775591,3.7301981 0.03294725,3.220921 0.03294725,2.6317573 Z"
    />
  </StyledSvg>
)